<template>
  <div class="relative">
    <button
      @click="toggleMenu"
      v-on-click-outside="() => (isMenuOpen = false)"
      data-cy="more-menu-button"
      class="cursor-pointer h-9 w-9 flex items-center justify-center border rounded hover:bg-blue-50"
    >
      <icon-base
        icon="vertical-dots"
        height="24"
        width="24"
        :icon-color="UTIL_COLORS.DEFAULT"
      />
    </button>
    <div
      v-if="isMenuOpen"
      class="moreButtonList absolute -bottom-1 right-0 transform translate-y-full bg-white rounded shadow-lg w-max cursor-pointer overflow-hidden"
    >
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { UTIL_COLORS } from "@/helpers/constants";

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
</script>

<style lang="postcss">
.moreButtonList li {
  @apply p-2 max-w-xs hover:bg-gray-100 truncate whitespace-nowrap;
}
</style>
